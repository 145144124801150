

.react-parallax img{
    max-width: unset;
}


::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(90, 90, 90, .7);
    border-radius: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(80, 80, 80, 0.2);
  }
  